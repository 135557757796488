import './App.css';
import DataFetcher from './classes/DataFetcher';
import React, { Component} from 'react';
//import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SettingStorage from './classes/SettingsStorage';
import Home from './Home/Home'
import "./classes/i18n";
import Digital from 'react-activity/dist/Digital';
import "react-activity/dist/Digital.css";

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} 
else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} 
else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
} 

class App extends Component {
  constructor(props){
    super(props);    
    this.state = {       
      data: [],
      updateId: "",
      appVersion: 0,
      allPlayerData: [],
      timerId: null    
    }    
  }

  handleVisibilityChange = async () => {
    if (!document[hidden]) {               
      this.updateIfNeeded();
      this.startTimer();
    }
    else {
      this.stopTimer();
    }
  }


  async componentDidMount(){   
    this.startTimer();
    document.addEventListener(visibilityChange, this.handleVisibilityChange, false);    
    this.updateIfNeeded();
  }

  startTimer = () => {
    const timerId = setInterval(this.updateIfNeeded, 60000);
    this.setState({ timerId });
  };

  stopTimer = () => {
    clearInterval(this.state.timerId);
    this.setState({ timerId: null });
  };

  updateIfNeeded = async() => {
    const versionInfo = await DataFetcher.getVersionInfo();    
    
    if (this.state.appVersion === 0)
    {
      this.setState({        
        appVersion: versionInfo.appVersion
      });
    }
    else if (versionInfo.appVersion !== this.state.appVersion)
    {
      this.setState({        
        appVersion: versionInfo.appVersion
      });
      window.location.reload();      
    }    
    if (versionInfo.updateId !== this.state.updateId)
    {
      await this.updateData();
      this.setState({
        updateId: versionInfo.updateId,        
      });
    }  
    this.setState({
      lastUpdated: versionInfo.lastUpdated,        
    });  
  }

  componentWillUnmount() { 
    this.stopTimer();   
    document.removeEventListener(visibilityChange, this.handleVisibilityChange);    
  }
  
  updateData = async() => {
    this.setState({
      isLoading: true     
      }); 
    const country = SettingStorage.getCountry();
    const data = await DataFetcher.fetchData(country);
            
    this.setState({
      data: data,
      isLoading: false,           
      });    
  }

  onCountryChanged = () => {
    this.updateData();
  }

  onAllPlayerDataChanged = (data) => {
    this.setState({ allPlayerData: data});
  }

  onVisibilitySettingChanged = (event, measurementIndex) => {    
    this.setState({ data: this.state.data});    
  }

  render()
  { 
    // if (this.state.updateId === "refresh") {     
    //     return (<div className='RefreshNotice'>NHLCountries has moved to a new server. Please refresh the page.</div>)
    // }
    return (
      <div className='MainDiv'>
        <Home 
          data={this.state.data}
          allPlayers={this.state.allPlayerData}
          lastUpdated={this.state.lastUpdated}
          updateId={this.state.updateId}
          appVersion={this.state.appVersion}
          isLoading={this.state.isLoading}
          countryChanged={this.onCountryChanged}
          allPlayerDataChanged={this.onAllPlayerDataChanged}/>  
          {this.state.isLoading && 
          <div className="Footer">      
            <div className='LoadingIndicator'>
              <Digital size={24}/>
            </div>
          </div>}
      </div>      
    );
  };
}

export default App;

